



























































































































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import 'leaflet/dist/leaflet.css';
import Drawer from '@/components/Drawer.vue';
import CreditsBar from '@/components/CreditsBar.vue';
import { User } from '@/types';
import { getAuth, signOut } from 'firebase/auth';

@Component({
  components: {
    Drawer,
    CreditsBar,
  },
})
export default class MapBar extends Vue {
  @Prop({ type: String, default: 'Mielopolis' }) readonly title!: string

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  get userName(): string {
    return this.currentUser.firstName ?? 'Utente';
  }

  get hivesCount(): number {
    return this.currentUser.hives && Object.keys(this.currentUser.hives)
      ? Object.keys(this.currentUser.hives).length : 0;
  }

  get hivesCredits(): number {
    return this.currentUser.hivesCredits ?? 0;
  }

  get honeycombsCredits(): number {
    return this.currentUser.honeycombsCredits ?? 0;
  }

  get creditsCount(): number {
    return this.hivesCredits + this.honeycombsCredits;
  }

  created() {
    const debugPrefix = 'MapBar: created()';
    console.log(debugPrefix); // eslint-disable-line no-console

    console.log(`${debugPrefix} - this.$route.query =`, this.$route.query); // eslint-disable-line no-console

    if (this.$route.query.login && this.$route.query.email) {
      this.$nextTick(() => this.onLogin());
    }
  }

  onToggleDrawer() {
    this.$store.dispatch('toggleDrawer');
  }

  onLogin() {
    const debugPrefix = 'MapBar: onLogin()';
    console.log(debugPrefix); // eslint-disable-line no-console

    this.$store.dispatch('openDialog', 'login');
  }

  onLogout() {
    const debugPrefix = 'MapBar: onLogout()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.isLoggedIn) {
      const auth = getAuth();
      signOut(auth).then(() => {
        console.log(`${debugPrefix} - signOut() success!`); // eslint-disable-line no-console

        this.$store.dispatch('unselectUser');
      });
    }
  }

  onMyHives() {
    const debugPrefix = 'MapBar: onMyHives()';
    console.log(debugPrefix); // eslint-disable-line no-console

    this.$store.dispatch('openDialog', 'myHives');
  }

  onMyCredits() {
    const debugPrefix = 'MapBar: onMyCredits()';
    console.log(debugPrefix); // eslint-disable-line no-console

    this.$store.dispatch('openDialog', 'myCredits');
  }
}
