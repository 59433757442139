









































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import * as L from 'leaflet';
import {
  LMap, LTileLayer, LControlZoom,
  LImageOverlay,
} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import HivesCarousel from '@/components/HivesCarousel.vue';
import MapBar from '@/components/MapBar.vue';
import Credits from '@/components/Credits.vue';
import MapLogo from '@/components/MapLogo.vue';
import SelectedApiary from '@/components/SelectedApiary.vue';
import {
  Apiary, Hive, Honeycomb, User, UserDetails,
} from '@/types';

@Component({
  components: {
    MapBar,
    HivesCarousel,
    Credits,
    MapLogo,
    SelectedApiary,
    LMap,
    LTileLayer,
    LControlZoom,
    LImageOverlay,
  },
  metaInfo(this: ApiaryView) {
    return {
      title: this.apiary?.name
        ? `Mielopolis.it - ${this.apiary.name} - Scegli la tua arnia`
        : 'Mielopolis.it',
    };
  },
})
export default class ApiaryView extends Vue {
  email = ''
  privacy = false

  crs = L.CRS.Simple

  zoom = 4
  center = [-128.0, 128.0]
  url = L.Browser.retina
    ? '/map-images/green-v7@2x/{z}/{x}/{y}.png'
    : '/map-images/green-v7/{z}/{x}/{y}.png'
  attribution = `© ${new Date().getFullYear()} Mielopolis`
  currentZoom = 4
  currentCenter = [-128.0, 128.0]
  showParagraph = false
  mapOptions = {
    zoomControl: false,
    zoomSnap: 0.5,
    maxBoundsViscosity: 0.8,
  }

  map = null as L.Map | null

  hideCircles = false

  markers: {
    [id: string]: L.Marker<any>;
  } = {}

  selectedHive: Hive | null = null
  canDeselctHive = false

  created() {
    console.log('Apiary: created() this.$route.params.id = ', this.$route.params.id); // eslint-disable-line no-console

    this.$store.dispatch('mapNotReady');

    this.$store.dispatch('selectApiary', this.$route.params.id);
  }

  destroyed() {
    console.log('Apiary: destroyed()'); // eslint-disable-line no-console

    this.$store.dispatch('unselectApiary');
  }

  mounted() {
    setTimeout(() => {
      console.log('Apiary: mounted() resize'); // eslint-disable-line no-console
      window.dispatchEvent(new Event('resize'));

      setTimeout(() => {
        if (Object.keys(this.markers).length === 0) {
          console.log('Apiary: mounted() initCircles'); // eslint-disable-line no-console

          this.initCircles();
        }
      }, 1000);
    }, 500);
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get currentUserId(): string | null {
    return this.currentUser.uid ?? null;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  get apiary(): Apiary | null {
    return this.$store.getters.apiary;
  }

  get apiaryHives(): Hive[] {
    return this.$store.getters.apiaryHives;
  }

  get hive(): Hive | null {
    return this.$store.getters.hive;
  }

  get hiveHoneycombs(): Honeycomb[] {
    return this.$store.getters.hiveHoneycombs;
  }

  get isRetina() {
    return L.Browser.retina;
  }

  get zoomClass() {
    const zoom = `${this.currentZoom}`.replace('.', '-');
    return `zoom-${zoom}`;
  }

  @Watch('apiaryHives')
  async onApiariesChange(val: Hive[], oldVal: Hive[]) {
    console.log('Apiary: onApiariesChange()'); // eslint-disable-line no-console

    if (val.length > 0) {
      setTimeout(() => {
        console.log('Apiary: onApiariesChange() chiamo initCircles()'); // eslint-disable-line no-console

        this.initCircles();
      }, 1000);
    }
  }

  zoomUpdate(zoom: any) {
    console.log('Apiary: zoomUpdate()'); // eslint-disable-line no-console
    this.hideCircles = true;
    this.currentZoom = zoom;
    setTimeout(() => {
      this.hideCircles = false;
    }, 1000);
  }

  centerUpdate(center: any) {
    this.currentCenter = center;
  }

  showLongText() {
    this.showParagraph = !this.showParagraph;
  }

  onMapReady() {
    console.log('Apiary: onMapReady()'); // eslint-disable-line no-console

    const mapRef: any = this.$refs.myMap;

    if ('mapObject' in mapRef === false) return;
    if (!(mapRef.mapObject instanceof L.Map)) return;

    this.map = mapRef.mapObject;

    if (!this.map) return;

    // eslint-disable-next-line no-underscore-dangle
    const originalInitTile = (L.GridLayer.prototype as any)._initTile;
    L.GridLayer.include({
      // eslint-disable-next-line no-underscore-dangle
      _initTile(tile: any) {
        originalInitTile.call(this, tile);

        const tileSize = this.getTileSize();

        // eslint-disable-next-line no-param-reassign, prefer-template
        tile.style.width = tileSize.x + 1 + 'px';
        // eslint-disable-next-line no-param-reassign, prefer-template
        tile.style.height = tileSize.y + 1 + 'px';
      },
    });

    const southWest = this.map.unproject([0, 2048], this.map.getMaxZoom());
    const northEast = this.map.unproject([2048, 0], this.map.getMaxZoom());

    // eslint-disable-next-line new-cap
    this.map.setMaxBounds(L.latLngBounds(southWest, northEast));
  }

  hiveURL(hive: Hive): string {
    return `${process.env.BASE_URL}map-images/${hive.image}`;
  }

  hiveBounds(hive: Hive) {
    const size = 10;
    return [[hive.posX - size, hive.posY - size], [hive.posX + size, hive.posY + size]];
  }

  hiveOwners(hive: Hive): string[] {
    return hive?.owners ?? [];
  }

  alreadyOwner(hive: Hive): boolean {
    if (this.currentUserId) return this.hiveOwners(hive).includes(this.currentUserId);

    return false;
  }

  isSelected(hive: Hive) {
    return this.selectedHive?.id === hive.id;
  }

  initCircles() {
    console.log('Apiary: initCircles()'); // eslint-disable-line no-console

    if (this.apiaryHives.length === 0) return;

    console.log('Apiary: initCircles() this.markers =', this.markers); // eslint-disable-line no-console
    if (Object.keys(this.markers).length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const id in this.markers) {
        if (Object.prototype.hasOwnProperty.call(this.markers, id)) {
          const marker = this.markers[id];
          marker.remove();
        }
      }
    }

    const iconSize = L.point(100, 100);

    console.log('Apiary: initCircles() this.apiaries =', this.apiaryHives); // eslint-disable-line no-console
    this.apiaryHives.forEach((hive) => {
      if (!this.map) {
        console.log('Apiary: initCircles() La mappa non è pronta!'); // eslint-disable-line no-console

        return;
      }

      const element = this.$refs[`hive-circle-${hive.id}`] as HTMLElement[];

      const myIcon = L.divIcon({
        className: 'my-div-icon',
        html: element[0],
        iconSize,
      });

      const myIconPosition = L.latLng(hive.posX, hive.posY);

      const marker = L.marker(myIconPosition, {
        icon: myIcon,
        // bubblingMouseEvents: false,
        // riseOnHover: true,
      }).addTo(this.map);

      if ('id' in hive && hive.id) this.markers[hive.id] = marker;
    });

    this.$store.dispatch('mapReady');
  }

  unselectHive() {
    console.log('Apiary: unselectHive()'); // eslint-disable-line no-console

    if (this.selectedHive) {
      this.selectedHive = null;
    }

    if (this.map) this.map.removeEventListener('click', this.unselectHive);
  }

  selectHive(id: string) {
    console.log('Apiary: selectHive() id =', id); // eslint-disable-line no-console

    // Seleziona l'apiario
    this.selectedHive = this.apiaryHives.find((a) => a.id === id) ?? null;

    // Se è stato effettivamente selezionato un apiario...
    if (this.selectedHive) {
      // ... lo porta in primo piano

      // eslint-disable-next-line no-restricted-syntax
      for (const markerId in this.markers) {
        if (Object.prototype.hasOwnProperty.call(this.markers, markerId)) {
          const marker = this.markers[markerId];
          marker.setZIndexOffset(id === this.selectedHive.id ? 1000 : 100);
        }
      }

      // Sposta la visuale sull'apiario
      setTimeout(() => {
        if (this.map && this.selectedHive) {
          const offset = (5 / this.currentZoom) + (5 / this.currentZoom) * 4;
          this.map.setView(
            L.latLng(this.selectedHive.posX + offset, this.selectedHive.posY),
            this.currentZoom < 4 ? 4 : this.currentZoom,
          );
        }
      }, 200);

      setTimeout(() => {
        if (this.map) {
          this.map.addEventListener('click', this.unselectHive);
        }
      }, 300);
    }
  }

  onHiveClick(hive: Hive) {
    console.log('Apiary: onHiveClick()'); // eslint-disable-line no-console

    if (this.map) this.map.removeEventListener('click', this.unselectHive);

    if (hive.id) this.selectHive(hive.id);
  }

  onHiveEnter(hive: Hive) {
    console.log('Apiary: onHiveEnter()'); // eslint-disable-line no-console

    if (!this.map) return;

    // eslint-disable-next-line no-restricted-syntax
    for (const id in this.markers) {
      if (Object.prototype.hasOwnProperty.call(this.markers, id)) {
        const marker = this.markers[id];
        marker.setZIndexOffset(id === hive.id ? 1000 : 100);
      }
    }
  }

  onHivesCarouselSelect(id: string | null) {
    console.log('Apiary: onHivesCarouselSelect() id =', id); // eslint-disable-line no-console

    if (id === null) {
      // Deseleziona l'apiario selezionato
      this.selectedHive = null;
    } else

    if (this.selectedHive?.id && this.selectedHive.id !== id) {
      // Seleziona il nuovo apiario
      this.selectHive(id);
    }
  }
}
