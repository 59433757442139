


























































































































































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import { Apiary } from '@/types';

@Component
export default class ApiaryDialog extends Vue {
  @Prop({ type: Object, default: null }) readonly apiary!: Apiary | null
  @Prop({ type: Boolean, default: false }) readonly hive!: boolean

  dialog = false
  loading = false

  get value(): boolean {
    return this.$store.getters.apiaryDialog;
  }

  @Watch('value')
  onValueChanged(val: boolean) {
    const debugPrefix = 'ApiaryDialog: onValueChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    const debugPrefix = 'ApiaryDialog: onDialogChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'apiary');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active > .v-card > .v-card__text');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  onCancel() {
    this.dialog = false;
  }

  onSelect() {
    console.log('ApiaryDialog: onSelect() id =', this.apiary?.id); // eslint-disable-line no-console

    if (!this.apiary?.id) return;

    this.$router.push({
      name: 'Apiary',
      params: {
        id: this.apiary.id,
      },
    });
  }

  onMap() {
    console.log('ApiaryDialog: onMap()'); // eslint-disable-line no-console

    this.$router.push({
      name: 'Map',
    });
  }

  get apiaries(): Apiary[] {
    return this.$store.getters.apiaries;
  }

  get apiaryName(): string {
    return this.apiary?.name ?? '';
  }

  get headerImage(): string {
    return this.apiary?.headerImage ? `/apiaries/${this.apiary.headerImage}` : '/apiaries/fake-header-01.jpg';
  }

  get beekeeperName(): string | null {
    return this.apiary?.beekeeper.name ?? null;
  }

  get longDescr(): string {
    if (!this.apiary || !this.apiary.descr) return '';

    return 'longDescr' in this.apiary && this.apiary.longDescr
      ? this.apiary.longDescr
      : this.apiary.descr;
  }

  get apiaryLocation(): string {
    return this.apiary?.location ?? 'Sconosciuta';
  }

  get hivesCount(): number {
    return this.apiary?.hivesCount ?? 0;
  }

  get apiaryFlora(): string[] {
    return this.apiary?.flora ?? [];
  }

  get apiaryHoney(): string[] {
    return this.apiary?.honey ?? [];
  }
}
