
















































import Vue from 'vue';

export default Vue.extend({
  name: 'MapLogo',

  components: {
  },

  data: () => ({
    publicPath: process.env.BASE_URL,
  }),

  computed: {
  },

  methods: {
  },
});
