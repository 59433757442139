































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import { Apiary } from '@/types';
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import ApiaryDialog from '@/components/ApiaryDialog.vue';

@Component({
  components: {
    ApiaryDialog,
  },
})
export default class SelectedApiary extends Vue {
  @Prop({ type: Object, default: null }) readonly apiary!: Apiary | null

  get headerImage(): string {
    return this.apiary?.headerImage
      ? `/apiaries/${this.apiary.headerImage}`
      : '/apiaries/fake-header-01.jpg';
  }

  onShow() {
    this.$store.dispatch('openDialog', 'apiary');
  }
}
