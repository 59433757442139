






































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import { User } from '@/types';
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import NewsIcon from '@/components/NewsIcon.vue';

@Component({
  components: {
    NewsIcon,
  },
})
export default class CreditsBar extends Vue {
  @Prop({ type: Number, default: -1 }) readonly requestedHivesCredits!: number
  @Prop({ type: Number, default: -1 }) readonly requestedHoneycombsCredits!: number

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get hivesCount(): number {
    return this.currentUser.hives && Object.keys(this.currentUser.hives)
      ? Object.keys(this.currentUser.hives).length : 0;
  }

  get hivesCredits() {
    return this.currentUser?.hivesCredits ?? 0;
  }

  get honeycombsCredits() {
    return this.currentUser?.honeycombsCredits ?? 0;
  }

  onMyHives() {
    this.$store.dispatch('openDialog', 'myHives');
  }

  onMyCredits() {
    this.$store.dispatch('openDialog', 'myCredits');
  }
}
