




















































































































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import 'leaflet/dist/leaflet.css';
import { User } from '@/types';
import { getAuth, signOut } from 'firebase/auth';

@Component
export default class Drawer extends Vue {
  drawer = false

  get value(): boolean {
    return this.$store.getters.drawerValue;
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  get isSuper(): boolean {
    return this.currentUser.isSuper;
  }

  get isBeekeeper(): boolean {
    return this.currentUser.isBeekeeper;
  }

  get canAdminAdoptions(): boolean {
    return this.currentUser.canAdminAdoptions;
  }

  get canAdminUsers(): boolean {
    return this.currentUser.canAdminUsers;
  }

  get userName(): string {
    return this.currentUser.firstName ?? 'Utente';
  }

  get hivesCount(): number {
    return this.currentUser.hives && Object.keys(this.currentUser.hives)
      ? Object.keys(this.currentUser.hives).length : 0;
  }

  get hivesCredits(): number {
    return this.currentUser.hivesCredits ?? 0;
  }

  get honeycombsCredits(): number {
    return this.currentUser.honeycombsCredits ?? 0;
  }

  get creditsCount(): number {
    return this.hivesCredits + this.honeycombsCredits;
  }

  created() {
    const debugPrefix = 'Drawer: created()';
    console.log(debugPrefix); // eslint-disable-line no-console
  }

  @Watch('value')
  onValueChanged(val: boolean) {
    const debugPrefix = 'Drawer: onValueChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    this.drawer = val;
  }

  @Watch('drawer')
  onDrawerChanged(val: boolean) {
    if (this.value !== val) {
      this.$store.dispatch('toggleDrawer');
    }
  }

  onLogout() {
    const debugPrefix = 'Drawer: onLogout()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.isLoggedIn) {
      const auth = getAuth();
      signOut(auth).then(() => {
        console.log(`${debugPrefix} - signOut() success!`); // eslint-disable-line no-console

        this.$store.dispatch('unselectUser');
      });
    }
  }

  onWelcome() {
    this.$store.dispatch('openDialog', 'welcome');
    this.drawer = false;
  }

  onHow() {
    this.$store.dispatch('openDialog', 'how');
    this.drawer = false;
  }

  onAbout() {
    this.$store.dispatch('openDialog', 'about');
    this.drawer = false;
  }

  onMyHives() {
    this.$store.dispatch('openDialog', 'myHives');
    this.drawer = false;
  }

  onMyCredits() {
    this.$store.dispatch('openDialog', 'myCredits');
    this.drawer = false;
  }

  onAdoptionsAdmin() {
    this.$store.dispatch('openDialog', 'adoptionsAdmin');
    this.drawer = false;
  }

  onUsersAdmin() {
    this.$store.dispatch('openDialog', 'usersAdmin');
    this.drawer = false;
  }

  onHivesAdmin() {
    this.$store.dispatch('openDialog', 'hivesAdmin');
    this.drawer = false;
  }
}
