















































































































































































































































































































































































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import { Apiary, Hive, User } from '@/types';
import AdoptSuccessDialog from '@/components/AdoptSuccessDialog.vue';
import AdoptErrorDialog from '@/components/AdoptErrorDialog.vue';
import Credits from '@/components/Credits.vue';
import ImageWithLabel from '@/components/ImageWithLabel.vue';

@Component({
  components: {
    AdoptSuccessDialog,
    AdoptErrorDialog,
    Credits,
    ImageWithLabel,
  },
})
export default class HiveDialog extends Vue {
  dialog = false

  selectedHoneycombs = []

  confirming = false

  result = {
    hives: 0,
    honeycombs: 0,
  }

  successDialog = false
  errorDialog = false

  created() {
    console.log('HiveDialog: created()'); // eslint-disable-line no-console
    this.confirming = false;
  }

  get value(): boolean {
    return this.$store.getters.hiveDialog;
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  get currentUserId(): string | null {
    return this.currentUser.uid ?? null;
  }

  get apiary(): Apiary | null {
    return this.$store.getters.apiary;
  }

  get apiaryImage(): string {
    return this.apiary?.headerImage
      ? `/apiaries/${this.apiary.headerImage}`
      : '/apiaries/fake-header-01.jpg';
  }

  get apiaryName(): string {
    return this.apiary?.name ?? '';
  }

  get apiaryLocation(): string {
    return this.apiary?.location ?? 'Sconosciuta';
  }

  get apiaryBeekeeperName(): string {
    return this.apiary?.beekeeper.name ?? 'Sconosciuto';
  }

  get apiaryHoney(): string[] {
    return this.apiary?.honey ?? [];
  }

  get apiaryFlora(): string[] {
    return this.apiary?.flora ?? [];
  }

  get hive(): Hive | null {
    return this.$store.getters.hive;
  }

  get hiveImage(): string {
    return this.hive?.photo
      ? `/hives/${this.hive.photo}`
      : '/apiaries/fake-header-01.jpg';
  }

  get hiveName(): string {
    return this.hive?.name ?? '';
  }

  get hivePopulation(): string {
    return this.hive?.population ?? 'Sconosciuta';
  }

  get hiveBehavior(): string {
    return this.hive?.behavior ?? 'Sconosciuto';
  }

  get hiveProductivity(): string {
    return this.hive?.productivity ?? 'Sconosciuta';
  }

  get honeycombsAvailable(): number {
    return this.hive?.honeycombsAvailable ?? 0;
  }

  get honeycombsCount(): number {
    return this.hive?.honeycombsCount ?? 0;
  }

  get hiveOwners(): string[] {
    return this.hive?.owners ?? [];
  }

  get alreadyOwner(): boolean {
    return this.currentUserId ? this.hiveOwners.includes(this.currentUserId) : false;
  }

  get dialogTitle(): string {
    return this.alreadyOwner
      ? `La tua arnia ${this.hiveName}`
      : `Vuoi adottare ${this.hiveName}?`;
  }

  get hivesCredits() {
    return this.currentUser?.hivesCredits ?? 0;
  }

  get honeycombsCredits() {
    return this.currentUser?.honeycombsCredits ?? 0;
  }

  get confirmMessageType(): string {
    if (this.alreadyOwner) {
      // L'utente ha adottato quest'arnia

      if (this.honeycombsAvailable > 0
        && this.selectedHoneycombs.length > 0
        && this.selectedHoneycombs.length <= this.honeycombsAvailable
        && this.honeycombsCredits >= this.selectedHoneycombs.length) return 'success';
    } else {
      // L'utente non ha adottato quest'arnia
      // eslint-disable-next-line no-lonely-if
      if (this.hivesCredits > 0
        && this.selectedHoneycombs.length <= this.honeycombsCredits) return 'success';
    }

    return 'error';
  }

  get confirmMessageText(): string {
    return this.confirmMessageType === 'error'
      ? 'Non hai abbastanza fiorini!'
      : 'Hai tutti i fiorini che servono!';
  }

  get confirmEnabled() {
    if (this.alreadyOwner) {
      // L'utente ha adottato quest'arnia

      if (this.honeycombsAvailable === 0) return false;

      if (this.honeycombsAvailable > 0
        && this.selectedHoneycombs.length > 0
        && this.selectedHoneycombs.length <= this.honeycombsAvailable
        && this.honeycombsCredits >= this.selectedHoneycombs.length) return true;
    } else {
      // L'utente non ha adottato quest'arnia
      // eslint-disable-next-line no-lonely-if
      if (this.hivesCredits > 0
        && this.selectedHoneycombs.length <= this.honeycombsCredits) return true;
    }

    return false;
  }

  get confirmText() {
    if (this.alreadyOwner) {
      // L'utente ha adottato quest'arnia

      if (this.honeycombsAvailable === 0) {
        return 'Favi esauriti';
      }

      if (this.honeycombsAvailable > 0) {
        return 'Aggiungi all\'adozione';
      }
    } else {
      // L'utente non ha adottato quest'arni

      if (this.hivesCredits > 0 && this.selectedHoneycombs.length === 0) {
        return 'Adotta l\'arnia (senza miele)';
      }

      if (this.hivesCredits > 0 && this.honeycombsCredits > 0
        && this.selectedHoneycombs.length === 1) {
        return 'Adotta l\'arnia + 1 favo';
      }

      if (this.hivesCredits > 0 && this.honeycombsCredits > 0
        && this.selectedHoneycombs.length > 1) {
        return `Adotta l'arnia + ${this.selectedHoneycombs.length} favi`;
      }
    }

    return 'Conferma';
  }

  get confirmColor() {
    if (this.confirmEnabled) return 'success';
    if (this.selectedHoneycombs.length > this.honeycombsCredits) return 'red';

    return 'primary';
  }

  @Watch('value')
  onValueChanged(val: boolean) {
    const debugPrefix = 'HiveDialog: onValueChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    const debugPrefix = 'HiveDialog: onDialogChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'hive');

      // Se il dialog viene chiuso,
      // deseleziona eventuali favi selezionati.
      if (val === false) {
        setTimeout(() => {
          this.selectedHoneycombs = [];
        }, 500);
      }
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active > .v-card > .v-card__text');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  onCancel() {
    this.dialog = false;
  }

  onMap() {
    console.log('HiveDialog: onMap()'); // eslint-disable-line no-console

    this.$router.push({
      name: 'Map',
    });
  }

  async onConfirm() {
    console.log('HiveDialog: onConfirm()'); // eslint-disable-line no-console

    this.confirming = true;

    try {
      this.result = await this.$store.dispatch(
        'adopt',
        {
          uid: this.currentUserId,
          honeycombs: this.selectedHoneycombs.length,
        },
      );

      console.log('HiveDialog: onConfirm() this.result =', this.result); // eslint-disable-line no-console

      this.dialog = false;
      this.successDialog = true;
    } catch (error) {
      console.log('HiveDialog: onConfirm() error =', error); // eslint-disable-line no-console

      this.dialog = false;
      this.errorDialog = true;
    }

    this.confirming = false;
  }

  onHelpCredits() {
    const debugPrefix = 'HiveDialog: onHelpCredits()';
    console.log(debugPrefix); // eslint-disable-line no-console

    this.$store.dispatch('openDialog', 'helpCredits');
  }

  onLogin() {
    const debugPrefix = 'HiveDialog: onLogin()';
    console.log(debugPrefix); // eslint-disable-line no-console

    this.dialog = false;
    this.$store.dispatch('openDialog', 'login');
  }

  onMyCredits() {
    const debugPrefix = 'HiveDialog: onMyCredits()';
    console.log(debugPrefix); // eslint-disable-line no-console

    this.dialog = false;
    this.$store.dispatch('openDialog', 'myCredits');
  }
}
