





































































































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch, Prop,
} from 'vue-property-decorator';

@Component
export default class AdoptSuccessDialog extends Vue {
  @Prop({
    type: Object,
    default: { hives: 0, honeycombs: 0 },
  }) readonly result!: { hives: number; honeycombs: number }

  dialog = false

  get value(): boolean {
    return this.$store.getters.adoptSuccessDialog;
  }

  get resultHives(): number {
    return this.result?.hives ?? 0;
  }

  get resultHoneycombs(): number {
    return this.result?.honeycombs ?? 0;
  }

  get successText(): string {
    if (this.resultHives > 0) {
      return this.resultHoneycombs === 0
        ? 'La tua adozione (senza miele) è andata a buon fine!'
        : 'La tua adozione è andata a buon fine!';
    }
    return this.resultHoneycombs === 1
      ? 'Abbiamo aggiunto un favo alla tua adozione!'
      : `Abbiamo aggiunto ${this.resultHoneycombs} favi alla tua adozione!`;
  }

  @Watch('value')
  onValueChanged(val: boolean) {
    const debugPrefix = 'AdoptSuccessDialog: onValueChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    const debugPrefix = 'AdoptSuccessDialog: onDialogChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'about');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active > .v-card > .v-card__text');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  onCancel() {
    this.dialog = false;
  }

  onMyHives() {
    this.dialog = false;
    this.$store.dispatch('openDialog', 'myHives');
  }
}
