
































import { User } from '@/types';
import {
  Vue, Component,
} from 'vue-property-decorator';

@Component
export default class NewsIcon extends Vue {
  get currentUser(): User {
    return this.$store.getters.user;
  }

  get newsCount(): number {
    // TODO: Recuperare il numero di news da leggere
    return 0;
  }

  onMyNews() {
    this.$store.dispatch('openDialog', 'myNews');
  }
}
