


















































































































import {
  Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import { Apiary, Hive, User } from '@/types';
import Progress from '@/components/Progress.vue';
import HiveDialog from '@/components/HiveDialog.vue';
import HiveSuperActions from '@/components/HiveSuperActions.vue';
import ImageWithLabel from '@/components/ImageWithLabel.vue';
import Flickity from 'flickity';

@Component({
  components: {
    Progress,
    HiveDialog,
    HiveSuperActions,
    ImageWithLabel,
  },
})
export default class HivesCarousel extends Vue {
  @Prop({ type: Object, default: null }) readonly hive!: Hive

  carousel: Flickity | null = null
  loading = false

  get apiary(): Apiary | null {
    return this.$store.getters.apiary;
  }

  get apiaryHives(): Hive[] {
    return this.$store.getters.apiaryHives;
  }

  get hidden(): boolean {
    return this.hive === null;
  }

  get isMapReady(): boolean {
    return this.$store.getters.isMapReady;
  }

  @Watch('isMapReady')
  onIsMapReadyChange(val: boolean, oldVal: boolean) {
    console.log('ApiariesCarousel: onIsMapReadyChange()'); // eslint-disable-line no-console

    if (val === true && oldVal === false) {
      this.carousel = new Flickity('.main-carousel', {
        wrapAround: true,
        pageDots: false,
        prevNextButtons: false,
      });

      this.carousel.on('change', this.onSlideChange);
    }
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get currentUserId(): string | null {
    return this.currentUser.uid ?? null;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  photo(hive: Hive): string {
    return hive?.photo ? `/hives/${hive.photo}` : '/apiaries/fake-header-01.jpg';
  }

  @Watch('apiaryHives')
  async onApiariesChange(val: Hive[], oldVal: Hive[]) {
    console.log('HivesCarousel: onApiariesChange()'); // eslint-disable-line no-console
  }

  @Watch('hive')
  async onHiveChange(val: Hive | null, oldVal: Hive | null) {
    console.log('HivesCarousel: onHiveChange()'); // eslint-disable-line no-console

    if (!this.carousel) return;

    const index = this.apiaryHives.findIndex((a) => a.id === val?.id);

    console.log('HivesCarousel: onHiveChange() index =', index); // eslint-disable-line no-console

    this.carousel.select(index);
  }

  isFull(hive: Hive): boolean {
    return hive.honeycombsAvailable === 0;
  }

  percent(hive: Hive): number {
    return (hive.honeycombsAvailable / hive.honeycombsCount) * 100;
  }

  hiveName(hive: Hive): string {
    return hive?.name ?? '';
  }

  hivePopulation(hive: Hive): string {
    return hive?.population ?? 'Sconosciuta';
  }

  hiveBehavior(hive: Hive): string {
    return hive?.behavior ?? 'Sconosciuto';
  }

  hiveProductivity(hive: Hive): string {
    return hive?.productivity ?? 'Sconosciuta';
  }

  hiveOwners(hive: Hive): string[] {
    return hive?.owners ?? [];
  }

  alreadyOwner(hive: Hive): boolean {
    if (this.currentUserId) return this.hiveOwners(hive).includes(this.currentUserId);

    return false;
  }

  progressText(hive: Hive): string {
    return hive.honeycombsAvailable === 0
      ? 'Nessun favo disponibile'
      : `${hive.honeycombsAvailable} favi su ${hive.honeycombsCount} disponibili`;
  }

  progressColor(hive: Hive): string {
    return hive.honeycombsAvailable === 0
      ? '#D50000'
      : '#F4BC46';
  }

  onSlideChange(index: number | undefined) {
    if (index !== undefined && index in this.apiaryHives) {
      this.$emit('select', this.apiaryHives[index].id);
    }
  }

  async onShow(id?: string) {
    if (this.loading) return;
    if (id === undefined) return;

    this.loading = true;

    await this.$store.dispatch('selectHive', id);

    setTimeout(() => {
      this.$store.dispatch('openDialog', 'hive');
      this.loading = false;
    }, 1000);
  }
}
