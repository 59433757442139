



































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import { User } from '@/types';
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class Credits extends Vue {
  @Prop({ type: Number, default: -1 }) readonly requestedHivesCredits!: number
  @Prop({ type: Number, default: -1 }) readonly requestedHoneycombsCredits!: number

  mounted() {
    console.log('Credits: mounted()'); // eslint-disable-line no-console
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get hivesCredits() {
    return this.currentUser?.hivesCredits ?? 0;
  }

  get honeycombsCredits() {
    return this.currentUser?.honeycombsCredits ?? 0;
  }

  get hivesCreditsClass() {
    if (this.requestedHivesCredits < 0) return '';
    return this.requestedHivesCredits > this.hivesCredits ? 'white--text' : 'green--text';
  }

  get hivesCreditsColor() {
    if (this.requestedHivesCredits < 0) return '';
    return this.requestedHivesCredits > this.hivesCredits ? 'red lighten-1' : 'green lighten-4';
  }

  get honeycombsCreditsClass() {
    if (this.requestedHoneycombsCredits < 0) return '';
    return this.requestedHoneycombsCredits > this.honeycombsCredits ? 'white--text' : 'green--text';
  }

  get honeycombsCreditsColor() {
    if (this.requestedHoneycombsCredits < 0) return '';
    return this.requestedHoneycombsCredits > this.honeycombsCredits ? 'red lighten-1' : 'green lighten-4';
  }
}
